import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

import '../../generic-page.scss';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const CSDatabaseGameModesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/database">Database</Link>
        </li>
        <li className="divider">/</li>
        <li>Game modes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_gamemodes.png"
            alt="Game modes"
          />
        </div>
        <div className="page-details">
          <h1>Game modes</h1>
          <h2>List of game modes available in the game.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          You will spend most of your time as a new player trying to finish the
          story. While in the past, the story mode was quite complicated as most
          of the stages required you to solve a puzzle with flying ships, in the
          last few months majority of those was replaced by simple stages with
          either waves of enemies you need to kill or with just a single boss
          enemy - so very similar to other gacha games.
        </p>
        <p>
          While this change drastically lowers the time you need to spend on
          progressions through the game, it also makes it a bit plain because
          all you do is enter a stage and auto-mode will clear it for you.
        </p>
        <SectionHeader title="Raids" />
        <p>
          You will gain access to Raids once you unlock Branches and start
          sending your employees out on various missions (1, 4 or 8 hour long).
          When they return with the spoils, there exist a small chance you will
          either spawn a Dive or a Raid boss.
        </p>
        <p>
          Raid boss requires you to use a 16 employee team and while the
          low-leveled raids are easily soloable, the highest ones requires help
          from your friends or company.
        </p>
        <p>
          What's important here is that when you spawn a Raid, you can share it
          with others and even without stepping foot inside, you're guaranteed
          the best rewards once they kill it. So don't be selfish and share your
          raids (but first grab some friends and join a company)!
        </p>
        <p>
          Here's a sample gameplay from the highest current raid level available
          in the game:
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="pzQZ2JDJ_1U" />
          </Col>
        </Row>
        <SectionHeader title="Dive" />
        <p>
          Dive is the other mode that you can get access to from Dispatches, and
          it's pretty similar to AFK Arena's Labyrinth. At least gameplay wise.
          Basically you prepare up to 5 fully-crewed ships and have to travel
          through a set of nodes, carefully picking your route to reach the boss
          at the end.
        </p>
        <p>
          While the early floors of Dive are pretty easy, the last five levels
          are <strong>considered the hardest content in the game</strong> - you
          have to fight way higher-leveled enemies than you and also it's the
          place where Evolved-1 dwells.
        </p>
        <p>
          You have to clear Dive 50 to unlock Evolved-1 farm spot and Matador
          ship recipe.
        </p>
        <p>Here is an example game play video from that mode:</p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="ACV5QtID3VU" />
          </Col>
        </Row>
        <p>Learn more about this mode in our guides:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/dive-introduction"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_diveintro.png"
                alt="Dive introduction"
              />
            }
          />
          <CategoryCard
            title="Dive - 49 and 50"
            link="/counter-side/guides/dive-49-50"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dive.png"
                alt="Dive 49 and 50"
              />
            }
          />
        </Row>
        <SectionHeader title="Guild Coop" />
        <p>
          Guild Coop is basically a hardcode version of normal Raid that's
          targeted toward Companies.
        </p>
        <p>
          Each week, a new set of Arenas open and the big bad Worm respawns. You
          first have to clear the Arenas (using teams of 8 employees) to gather
          Artifacts that will make killing the Worm much much easier. But
          beware! Don't attack any Arena randomly and coordinate with your
          Company first, because you get limited attempts each week.
        </p>
        <p>
          When you finally get the Artifacts, you can attack the Worm. It's HP
          is much higher going up to 70kk for the last stage of the boss and you
          can't auto the boss like you do in normal Raids. Also there are
          certain employees like{' '}
          <Link to="/counter-side/characters/mone">Mone</Link> or{' '}
          <Link to="/counter-side/characters/john-mason">John Mason</Link> that
          make this fight much easier.
        </p>
        <p>Here's a sample video of a boss attempt:</p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="-amh4Clgpl0" />
          </Col>
        </Row>
        <p>And here's an overview of the mode:</p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="mGXuT3AtNyU" />
          </Col>
        </Row>
        <p>Learn more about this mode in our guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/guild-coop"
            image={
              <StaticImage
                src="../../../images/counterside/categories/icon_guild_raid.png"
                alt="Guild raid"
              />
            }
          />
        </Row>
        <SectionHeader title="Danger Close" />
        <p>
          Danger Close is Counter Side's version of the boss rush mode that's
          pretty popular in other other games. The mode is split into 2-week
          Seasons. During the first one, you daily get 3 chances to attack one
          of two bosses, and the second week acts as break period.
        </p>
        <p>
          There are currently 8 Danger Close bosses, out of which 6 are
          available in SEA Region. Each Seasons picks two random bosses and to
          make things spicier, each boss has a random buff and debuff attached
          to them. This makes it so you can't use identical teams whenever the
          boss returns and you need to adjust to the new challenge.
        </p>
        <p>
          Furthermore, there exist one more important limitation - you can't use
          the same employee, ships or operators on both bosses. This forces you
          to have a wide roster or even to raise duplicates of certain
          employees/ships that are very strong in this mode.
        </p>
        <p>Here's a example boss fight:</p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="n0pz3FjLiis" />
          </Col>
        </Row>
        <p>Learn more about this mode in our guides:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/danger-close"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_dangerclose_overview.png"
                alt="Danger Close Overview"
              />
            }
          />
        </Row>
        <SectionHeader title="Shadow Palace" />
        <p>
          Shadow Palace is PVE mode where you need to conquer a dungeon with 5
          floors and 5 stages on each one, but every stage has different
          restrictions - for example some ban Rangers, other Strikers.
        </p>
        <p>
          How it works?{' '}
          <strong>
            Each day, you get one Entry Ticket and you can save up to 7
          </strong>
          . You need a Ticket to enter the floor of your choosing and you get 3
          Hearts in return. You lose 1 Heart when you fail a stage and if you
          lose all 3 you will be kicked out from the floor and will need another
          Ticket to enter.
        </p>
        <p>
          What's important is that{' '}
          <strong>the Skip option isn't available in this mode</strong> which
          means you have to create a set of teams that are able to tackle one of
          the floors and also do it on auto with 100% win rate.
        </p>
        <p>Learn more about this mode in our guides:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/shadow-palace"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_overview.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Rewards"
            link="/counter-side/guides/shadow-palace-rewards"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_rewards.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Shop guide"
            link="/counter-side/guides/shadow-palace-shop"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_shop.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Spectral gear"
            link="/counter-side/guides/shadow-palace-spectral"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_spectral.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Tips for all floors"
            link="/counter-side/guides/shadow-palace-tips"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_all.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 1"
            link="/counter-side/guides/shadow-palace-floor-1"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_1.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 2"
            link="/counter-side/guides/shadow-palace-floor-2"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_2.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 3"
            link="/counter-side/guides/shadow-palace-floor-3"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_3.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 4"
            link="/counter-side/guides/shadow-palace-floor-4"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_4.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 5"
            link="/counter-side/guides/shadow-palace-floor-5"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_5.png"
                alt="Shadow Palace"
              />
            }
          />
        </Row>
        <SectionHeader title="Relic Dungeons" />
        <p>
          Relic Dungeons is a mode where you will spend most of your Eternium
          because currently,{' '}
          <strong>
            it's the only place in the game where you can farm T7 Gear and T7
            Materials
          </strong>{' '}
          that are used both in crafting new T7 gear and upgrading your current
          T6 gear to T7.
        </p>
        <p>
          Alongside Dive 50, Relic Dungeons are the hardest content in the game
          and{' '}
          <strong>each of the three Dungeons has a set of restrictions</strong>{' '}
          that force you to use a certain composition to clear it.
        </p>
        <p>Learn more about this mode in our guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Mode overview"
            link="/counter-side/guides/relic-dungeons"
            image={
              <StaticImage
                src="../../../images/counterside/categories/guide_relicdungeons.png"
                alt="Relic Dungeons"
              />
            }
          />
        </Row>
        <SectionHeader title="Challenge Mode" />
        <p>
          Campaign-like-mode but the difficulty is through the roof and there's
          tons of restrictions. Similar to Shadow Palace, you need Entry Tickets
          to attack a stage and if you quit before the timer reaches zero, you
          will get the ticket back. You can stack up to 10 Tickets and you get 1
          per day.{' '}
        </p>
        <p>
          You can obtain characters shards (Stronghold, AT1-Lincoln, ATAC-130,
          Terminator, Sweeper, ATF-35 and Brownie Moore) and T6 Limited gear
          here.
        </p>
        <p>
          While the first two Acts are pretty easy to clear for someone who
          plays the game for a month or two at least, Act 3 is where the
          struggle begins.
        </p>
      </div>
    </DashboardLayout>
  );
};

export default CSDatabaseGameModesPage;

export const Head: React.FC = () => (
  <Seo
    title="Game modes | Counter Side | Prydwen Institute"
    description="List of game modes available in the game."
  />
);
